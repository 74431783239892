import React from "react";
import classNames from "classnames";
import Icon from "components/UI/Icon";

import styles from "./Totals.module.scss";

export default ({ list }) => {
  let totals = [];

  list.map((item) => {
    const total =
      item.items.filter((i) => i.totalEvaluation !== 0).length > 0
        ? item.items.reduce((a, b) => a + b.totalEvaluation, 0) /
          item.items.filter((i) => i.totalEvaluation !== 0).length
        : 0;

    if (!totals.some((total) => total.groupTitle === item.groupTitle)) {
      totals.push({
        groupTitle: item.groupTitle,
        score: Math.round(total, 10),
      });
    }
  });

  return (
    <div className={styles.totalsWrapper}>
      <table className={styles.totals}>
        <thead>
          <tr>
            <th>
              <p>Executive readiness</p>
            </th>
            <th>
              <p>Development area</p>
            </th>
            <th>
              <p>Near requirements</p>
            </th>
            <th>
              <p>Meets requirements</p>
            </th>
            <th>
              <p>Exceeds requirements</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {totals.slice(0, 2).map((total, index) => (
            <tr key={"Total1-" + index}>
              <td className={styles.name}>{total.groupTitle}</td>
              {[...Array(4)].map((cell, index) => {
                return (
                  <td
                    className={classNames(
                      index === total.score - 1 && styles.selected
                    )}
                    key={"TotalCell1" + index}
                  >
                    {index === total.score - 1 && <Icon icon="Check" />}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <table className={styles.totals}>
        <thead>
          <tr>
            <th>
              <p>Executive potential</p>
            </th>
            <th>
              <p>Development area</p>
            </th>
            <th>
              <p>Near requirements</p>
            </th>
            <th>
              <p>Meets requirements</p>
            </th>
            <th>
              <p>Exceeds requirements</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {totals.slice(2, 4).map((total, index) => (
            <tr key={"Total2-" + index}>
              <td className={styles.name}>{total.groupTitle}</td>
              {[...Array(4)].map((cell, index) => {
                return (
                  <td
                    className={classNames(
                      index === total.score - 1 && styles.selected
                    )}
                    key={"TotalCell2" + index}
                  >
                    {index === total.score - 1 && <Icon icon="Check" />}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
