import React, { useState } from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import PhoneInput from "react-phone-number-input";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";
import Icon from "components/UI/Icon";
import DropDown from "components/UI/DropDown";
import Label from "components/UI/Label";
import Summary from "./Summary";
import KeyHighlights from "./KeyHighlights";
import Notes from "./Notes";
import Attachments from "./Attachments";
import NewAttachment from "./NewAttachment";
import CandidateData from "./CandidateData";
import compose from "./compose";

import styles from "./AdminSection.module.scss";

export default compose(
  ({
    candidate,
    handleUpsertPerson,
    handleUpsertCandidateLinkedIn,
    handleRemoveCandidateLinkedin,
    handleUpsertCandidatePhone,
    handleGenerateSummary,
    isGenerating,
    toggleGenerating,
    editSummary,
    setEditSummary,
    summary,
    setSummary,
    gptModal,
    setGptModal,
  }) => {
    const [linkedInValue, updateLinkedInValue] = useState(
      !candidate.linkedIn ? null : candidate.linkedIn
    );
    const [emailValue, updateEmailValue] = useState(
      !candidate.email ? null : candidate.email
    );
    const [editLinkedIn, setEditLinkedIn] = useState(false);
    const [linkedInError, setLinkedInError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(
      !candidate.phone ? null : candidate.phone
    );
    const [firstNameValue, setFirstNameValue] = useState(
      candidate.first_name || ""
    );
    const [lastNameValue, setLastNameValue] = useState(
      candidate.last_name || ""
    );
    const [editName, setEditName] = useState(!candidate.name);

    const isContact = candidate.__typename == "LonglistedPersonType";
    const isInviteSent = candidate.invite;

    const hasLinkedInData = candidate.hasLinkedInData;

    // TODO: Refractor all changeable fields

    return (
      <div className={styles.adminSection}>
        <div className={classNames(styles.user, isContact && styles.contact)}>
          <div className={styles.left}>
            <div className={styles.avatarWrapper}>
              {isContact ? (
                <div className={styles.avatar}>
                  <Icon icon="User" />
                </div>
              ) : (
                <UserAvatar member={candidate} size="large" />
              )}
            </div>
            <div className={styles.info}>
              <div className={styles.labels}>
                {isContact && isInviteSent && (
                  <div className={styles.label}>
                    <Label color="primary">Invite sent</Label>
                  </div>
                )}
                {isContact && !isInviteSent && (
                  <div className={styles.label}>
                    <Label color="yellow">Not invited</Label>
                  </div>
                )}
                {!isContact && !candidate.readyToRender && (
                  <div className={styles.label}>
                    <Label color="yellow">Profile incomplete</Label>
                  </div>
                )}
                {(isContact || candidate.readyToRender) && (
                  <>
                    {hasLinkedInData ? (
                      <div className={styles.label}>
                        <Label color="green">LinkedIn data imported</Label>
                      </div>
                    ) : (
                      <div className={styles.label}>
                        <Label color="grey">No LinkedIn data</Label>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.nameWrapper}>
                {editName ? (
                  <div className={styles.nameInputs}>
                    <input
                      className={styles.nameInput}
                      type="text"
                      placeholder="First name"
                      value={firstNameValue}
                      onChange={(e) => setFirstNameValue(e.target.value)}
                    ></input>
                    <input
                      className={styles.nameInput}
                      type="text"
                      placeholder="Last name"
                      value={lastNameValue}
                      onChange={(e) => setLastNameValue(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <h2 className={styles.name}>
                    {firstNameValue + " " + lastNameValue}
                  </h2>
                )}
                {isContact && (
                  <>
                    {!editName ? (
                      <div className={styles.editNameIcon}>
                        <Button
                          bStyle={["none"]}
                          onClick={() => setEditName(true)}
                        >
                          <Icon icon="Edit" />
                        </Button>
                      </div>
                    ) : (
                      <div className={styles.saveNameIcon}>
                        <Button
                          bStyle={["none"]}
                          onClick={() => {
                            const data = {
                              first_name: firstNameValue,
                              last_name: lastNameValue,
                              phone: undefined,
                              email: undefined,
                            };
                            handleUpsertPerson(data);
                            setEditName(false);
                          }}
                        >
                          <Icon icon="Check" />
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
              <p className={styles.position}>
                <strong>{candidate.position.name}</strong>
                {" / "}
                {candidate.position.parentTeam.name}
              </p>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.buttonGroup}>
              <div className={styles.buttonGroupLeft}>
                {isContact ? (
                  <div
                    className={classNames(
                      styles.dropdownButton,
                      "dropdownWrapper"
                    )}
                  >
                    <Popup
                      trigger={
                        <div
                          className={classNames(
                            styles.button,
                            !emailValue && styles.empty
                          )}
                        >
                          <Button bStyle={["secondary", "verySmall"]}>
                            <Icon icon="Mail" marginRight />
                            {emailValue || "Email"}
                            {!emailValue && (
                              <span
                                className={classNames(styles.icon, styles.plus)}
                              >
                                <Icon icon="Plus" />
                              </span>
                            )}
                            {emailValue && (
                              <span
                                className={classNames(styles.icon, styles.edit)}
                              >
                                <Icon icon="Edit" />
                              </span>
                            )}
                          </Button>
                        </div>
                      }
                      position="bottom center"
                      arrow={false}
                    >
                      {(close) => (
                        <>
                          <div
                            className={classNames(styles.link, styles.phone)}
                          >
                            <div className={styles.phoneNumber}>
                              <input
                                type="text"
                                placeholder="Add email"
                                value={emailValue}
                                onChange={(e) => {
                                  updateEmailValue(e.target.value);
                                }}
                              />
                            </div>
                            <div
                              className={classNames(
                                styles.saveButton,
                                styles.active
                              )}
                            >
                              <Button
                                bStyle={["none"]}
                                onClick={() => {
                                  const data = {
                                    first_name: undefined,
                                    last_name: undefined,
                                    phone: undefined,
                                    email: emailValue,
                                  };
                                  handleUpsertPerson(data, close);
                                }}
                              >
                                <Icon icon="Check" />
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Popup>
                  </div>
                ) : (
                  <Button bStyle={["secondary", "verySmall"]}>
                    <Icon icon="Mail" marginRight />
                    {emailValue || "Email"}
                  </Button>
                )}

                <div
                  className={classNames(
                    styles.dropdownButton,
                    "dropdownWrapper"
                  )}
                >
                  <Popup
                    trigger={
                      <div
                        className={classNames(
                          styles.button,
                          !phoneNumber && styles.empty
                        )}
                      >
                        <Button bStyle={["secondary", "verySmall"]}>
                          <Icon icon="Phone" marginRight />
                          {phoneNumber || "Phone"}
                          {!phoneNumber && (
                            <span
                              className={classNames(styles.icon, styles.plus)}
                            >
                              <Icon icon="Plus" />
                            </span>
                          )}
                          {phoneNumber && (
                            <span
                              className={classNames(styles.icon, styles.edit)}
                            >
                              <Icon icon="Edit" />
                            </span>
                          )}
                        </Button>
                      </div>
                    }
                    position="bottom center"
                    arrow={false}
                  >
                    {(close) => (
                      <>
                        <div className={classNames(styles.link, styles.phone)}>
                          <div className={styles.phoneNumber}>
                            <PhoneInput
                              countrySelectProps={{ unicodeFlags: true }}
                              country="ee"
                              value={phoneNumber}
                              onChange={setPhoneNumber}
                              placeholder="Enter phone number"
                            />
                          </div>
                          <div
                            className={classNames(
                              styles.saveButton,
                              styles.active
                            )}
                          >
                            <Button
                              bStyle={["none"]}
                              onClick={() => {
                                if (isContact) {
                                  const data = {
                                    first_name: undefined,
                                    last_name: undefined,
                                    phone: phoneNumber,
                                    email: undefined,
                                    linkedIn: undefined,
                                  };
                                  handleUpsertPerson(data, close);
                                } else {
                                  handleUpsertCandidatePhone(
                                    phoneNumber,
                                    close
                                  );
                                }
                              }}
                            >
                              <Icon icon="Check" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Popup>
                </div>
                <div
                  className={classNames(
                    styles.dropdownButton,
                    "dropdownWrapper"
                  )}
                >
                  <Popup
                    trigger={
                      <div
                        className={classNames(
                          styles.button,
                          !linkedInValue && styles.empty
                        )}
                      >
                        <Button bStyle={["secondary", "verySmall"]}>
                          <Icon icon="LinkedIn" marginRight />
                          LinkedIn
                          {!linkedInValue && (
                            <span
                              className={classNames(styles.icon, styles.plus)}
                            >
                              <Icon icon="Plus" />
                            </span>
                          )}
                          {linkedInValue && (
                            <span
                              className={classNames(styles.icon, styles.edit)}
                            >
                              <Icon icon="Edit" />
                            </span>
                          )}
                        </Button>
                      </div>
                    }
                    position="bottom center"
                    arrow={false}
                  >
                    {(close) => (
                      <>
                        {candidate.linkedIn && !editLinkedIn && (
                          <div className={styles.linkedIn}>
                            <DropDown>
                              <li>
                                <a target="_blank" href={linkedInValue}>
                                  <Button bStyle={["none"]}>
                                    <span>
                                      <Icon icon="Arrow" marginRight />
                                      Visit link
                                    </span>
                                    <span className={styles.external}>
                                      <i className="fas fa-external-link-alt" />
                                    </span>
                                  </Button>
                                </a>
                              </li>
                              <li>
                                <Button
                                  bStyle={["none"]}
                                  onClick={() => setEditLinkedIn(true)}
                                >
                                  <Icon icon="Edit" marginRight />
                                  Edit link
                                </Button>
                              </li>
                              <li>
                                <Button
                                  bStyle={["none"]}
                                  onClick={() => {
                                    if (isContact) {
                                      const data = {
                                        first_name: undefined,
                                        last_name: undefined,
                                        phone: undefined,
                                        email: undefined,
                                        linkedIn: null,
                                      };
                                      handleUpsertPerson(data, close);
                                    } else {
                                      handleRemoveCandidateLinkedin(
                                        setEditLinkedIn,
                                        close
                                      );
                                    }

                                    updateLinkedInValue(null);
                                  }}
                                >
                                  <Icon icon="Delete" marginRight />
                                  Remove link
                                </Button>
                              </li>
                            </DropDown>
                          </div>
                        )}
                        {(!candidate.linkedIn || editLinkedIn) && (
                          <div
                            className={classNames(
                              styles.link,
                              styles.linkedin,
                              linkedInError && styles.error
                            )}
                          >
                            <input
                              type="text"
                              placeholder="Add LinkedIn link"
                              value={linkedInValue}
                              onChange={(e) => {
                                updateLinkedInValue(e.target.value);
                                if (linkedInError) {
                                  setLinkedInError(false);
                                }
                              }}
                            />
                            <div
                              className={classNames(
                                styles.saveButton,
                                linkedInValue && styles.active
                              )}
                            >
                              <Button
                                bStyle={["none"]}
                                onClick={() => {
                                  setLinkedInError(false);
                                  const validUrl = new RegExp(
                                    `^(http|https)://?[a-z0-9]+([\-\.]linkedin+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`
                                  );
                                  if (validUrl.test(linkedInValue)) {
                                    if (isContact) {
                                      const data = {
                                        first_name: undefined,
                                        last_name: undefined,
                                        phone: undefined,
                                        email: undefined,
                                        linkedIn: linkedInValue,
                                      };
                                      handleUpsertPerson(data, close);
                                    } else {
                                      handleUpsertCandidateLinkedIn(
                                        linkedInValue,
                                        close
                                      );
                                    }

                                    setEditLinkedIn(false);
                                  } else {
                                    setLinkedInError(true);
                                  }
                                }}
                                disabled={!linkedInValue}
                              >
                                <Icon icon="Check" />
                              </Button>
                            </div>
                            {linkedInError && (
                              <p className={styles.linkedInError}>
                                Incorrect URL. Please copy-paste the URL from
                                the candidates' LinkedIn profile.
                              </p>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Popup>
                </div>
              </div>
              <div className={styles.buttonGroupRight}>
                <div
                  className={classNames(
                    styles.attachments,
                    candidate.attachments.length === 0 && styles.empty
                  )}
                >
                  <div className={styles.leftSide}>
                    <Icon icon="Clipboard" marginRight />
                    <p>Attachments</p>
                  </div>
                  <NewAttachment candidate={candidate} />
                </div>
                {candidate.attachments.length > 0 && (
                  <Attachments candidate={candidate} />
                )}
              </div>
            </div>
          </div>
        </div>
        {candidate.accountDimGroups && <CandidateData candidate={candidate} />}
        <div className={styles.summary}>
          <Summary
            candidate={candidate}
            content={candidate.candidateSummary}
            hasLinkedInData={hasLinkedInData}
            handleGenerateSummary={handleGenerateSummary}
            isGenerating={isGenerating}
            toggleGenerating={toggleGenerating}
            editSummary={editSummary}
            setEditSummary={setEditSummary}
            summary={summary}
            setSummary={setSummary}
            gptModal={gptModal}
            setGptModal={setGptModal}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <h5 className={styles.title}>Key highlights</h5>
            <p className={styles.subtitle}>
              Key highlights generated by Wisnio and added/customized by you
            </p>
          </div>
          <KeyHighlights
            candidate={candidate}
            keyHighlights={candidate.keyHighlights}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <h5 className={styles.title}>Notes</h5>
            <p className={styles.subtitle}>
              Additional notes and comments about the candidate
            </p>
          </div>
          <Notes candidate={candidate} notes={candidate.notes} />
        </div>
      </div>
    );
  }
);
