import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import Icon from "components/UI/Icon";
import { Draggable } from "react-beautiful-dnd";
import EllipsisButton from "components/UI/EllipsisButton";
import DropDown from "components/UI/DropDown";
import DropDownSeparator from "components/UI/DropDownSeparator";
import Button from "components/UI/Button";
import ResendInvite from "components/ResendInvite";
import SendInviteToWisnio from "components/SendInviteToWisnio";
import UserAvatar from "components/UserAvatar";
import { printoutRoute } from "client/authRoutes";
import LinkHandler from "./LinkHandler";
import styles from "./Kanban.module.scss";

export default ({
  position,
  column,
  item,
  index,
  columnIndex,
  setRemoveModal,
  setOnboardModal,
  handleSendInvite,
}) => {
  const [cardLabels, setCardLabels] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);

  useEffect(() => {
    const labels = [];

    if (item.type == "invite") {
      labels.push({
        title: "Invite sent",
        color: "yellow",
      });
    }

    if (item.type !== "invite" && item.isIncomplete && item.type !== "person") {
      labels.push({
        title: "Profile incomplete",
        color: "yellow",
      });
    }

    if (item.type === "person" && !item.inviteSent) {
      labels.push({
        title: "Contact not invited",
        color: "grey",
      });
    }

    if (item.type === "person" && item.inviteSent) {
      labels.push({
        title: "Invite sent",
        color: "yellow",
      });
    }

    if (item.type === "onboarding") {
      labels.push({
        title: "Onboarding",
        color: "green",
      });
    }

    setCardLabels(labels);

    const items = [
      {
        category: "links",
        items: [],
      },
      {
        category: "print",
        items: [],
      },
      {
        category: "actions",
        items: [],
      },
    ];

    if (item.type === "person") {
      items[0].items.push({
        type: "component",
        component: (
          <SendInviteToWisnio
            action={() => handleSendInvite(item)}
            icon={item.inviteSent ? "Send" : "AddUser"}
            text={item.inviteSent ? "Resend invite" : "Invite to Wisnio"}
            color={item.inviteSent ? "" : "primary"}
          />
        ),
      });
    }

    if (item.type !== "invite") {
      items[0].items.push({
        type: "link",
        link: `/${position.parent_org_id}/team/${
          position.parent_team_id
        }/position/${position.id}/${
          item.type === "user" ? "candidate" : item.type
        }/${item.id}`,
        icon: "User",
        title: "View profile",
      });
    }

    if (item.type === "invite") {
      items[2].items.push({
        type: "component",
        component: <ResendInvite invite={item} />,
      });
    }

    items[0].items.push({
      type: "link",
      link: `${item.link}/interviewguide${
        position.assessmentScorecardId
          ? "/" + position.assessmentScorecardId
          : ""
      }`,
      icon: "Clipboard",
      title: "Interview & Evaluate",
    });

    if (position.id !== 4088) {
      items[1].items.push({
        type: "link",
        link: `${printoutRoute}/printout/interview_guide/${position.parent_org_id}/${position.parent_team_id}/${position.id}/${item.id}/${item.first_name}_${item.last_name}_interview_guide.pdf`,
        newTab: true,
        icon: "Print",
        title: "Print interview guide",
      });
    }

    if (item.type !== "person") {
      items[0].items.push({
        type: "button",
        action: () => {
          setOnboardModal(item);
        },
        icon: "Extraversion",
        title: "Onboard",
      });
    }

    items[1].items.push({
      type: "link",
      link: `${printoutRoute}/printout/candidate/${position.parent_org_id}/${position.parent_team_id}/${position.id}/${item.id}/${item.first_name}_${item.last_name}_candidate_profile.pdf`,
      newTab: true,
      icon: "Print",
      title: "Print profile",
    });

    items[2].items.push({
      type: "button",
      action: () => setRemoveModal(item),
      icon: "Close",
      title: "Remove candidate",
    });

    // {
    //   item.type === "invite" && (
    //     <li>
    //       <ResendInvite invite={item} />
    //     </li>
    //   );
    // }
    setDropdownItems(items);
  }, [item]);

  return (
    <Draggable
      key={item.itemId}
      draggableId={`${item.itemId}`}
      index={index}
      className={styles.draggable}
      isDragDisabled={item.type === "invite" || item.type === "onboarding"}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={classNames(
              styles.card,
              item.type === "onboarding" && styles.onboarding,
              item.type === "person" && styles.contact
            )}
          >
            <div
              className={classNames(
                styles.top,
                item.type !== "invite" &&
                  !item.isIncomplete &&
                  position.parentTeam.members.some((m) => m.readyToRender) &&
                  styles.hover
              )}
            >
              <div className={styles.left}>
                <LinkHandler
                  link={item.link}
                  condition={item.type !== "invite"}
                  key={`candidate${Math.random(10)}`}
                >
                  <div className={styles.avatarWrapper}>
                    <span className={styles.avatar}>
                      {item.type === "person" ? (
                        <div className={styles.person}>
                          <Icon icon="User" />
                        </div>
                      ) : (
                        <UserAvatar
                          member={item}
                          size="small"
                          isInvite={item.type === "invite"}
                        />
                      )}
                    </span>
                    <span className={styles.dragIcon}>
                      <Icon icon="Drag" />
                    </span>
                  </div>
                  <div className={styles.text}>
                    <h5
                      className={classNames(
                        styles.cardTitle,
                        !item.name ? styles.centered : ""
                      )}
                    >
                      {item.type === "invite"
                        ? item.email
                        : item.name
                        ? item.name
                        : item.email}
                    </h5>
                    {item.name && (
                      <p className={styles.cardDesc}>{item.email}</p>
                    )}
                  </div>
                </LinkHandler>
              </div>
              <div className={styles.right}>
                <div className={styles.button}>
                  {item.type !== "onboarding" && (
                    <div className="dropdownWrapper" id="dropdownMenu">
                      <Popup
                        keepTooltipInside={`#kanbanColumn-${columnIndex}`}
                        trigger={<EllipsisButton type="button" />}
                        arrowStyle={{ display: "none" }}
                        position="bottom right"
                      >
                        <DropDown>
                          {dropdownItems.map((dropdownItem, index) => (
                            <div key={"DropdownItem-" + index}>
                              {dropdownItem.items.map(
                                (dropdownSubitem, index2) => {
                                  if (dropdownSubitem.type === "link") {
                                    return (
                                      <li
                                        key={"DropdownListItem-" + index2}
                                        className={classNames(
                                          styles[dropdownSubitem.color]
                                        )}
                                      >
                                        <a
                                          href={dropdownSubitem.link}
                                          target={
                                            dropdownSubitem.newTab
                                              ? "_blank"
                                              : ""
                                          }
                                        >
                                          <Button
                                            type="button"
                                            bStyle={["none"]}
                                          >
                                            <Icon
                                              icon={dropdownSubitem.icon}
                                              marginRight
                                            />
                                            {dropdownSubitem.title}
                                          </Button>
                                        </a>
                                      </li>
                                    );
                                  }
                                  if (dropdownSubitem.type === "button") {
                                    return (
                                      <li
                                        key={"DropdownListItem-" + index2}
                                        className={classNames(
                                          styles[dropdownSubitem.color]
                                        )}
                                      >
                                        <Button
                                          type="button"
                                          bStyle={["none"]}
                                          onClick={dropdownSubitem.action}
                                        >
                                          <Icon
                                            icon={dropdownSubitem.icon}
                                            marginRight
                                          />
                                          {dropdownSubitem.title}
                                        </Button>
                                      </li>
                                    );
                                  }
                                  if (dropdownSubitem.type === "component") {
                                    return (
                                      <li key={"DropdownListItem-" + index2}>
                                        {dropdownSubitem.component}
                                      </li>
                                    );
                                  }
                                }
                              )}
                              {dropdownItem.items.length > 0 &&
                                index + 1 !== dropdownItems.length && (
                                  <DropDownSeparator />
                                )}
                            </div>
                          ))}
                        </DropDown>
                      </Popup>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {cardLabels.length > 0 && (
              <div className={styles.bottom}>
                {cardLabels.map((label, index) => (
                  <span
                    key={"Label-" + index}
                    className={classNames(styles.label, styles[label.color])}
                  >
                    {label.title}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};
