import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import Button from "components/UI/Button";
import compose from "./compose";
import SurveyHeader from "./SurveyHeader";
import SurveyFooter from "./SurveyFooter";
import UITemplate from "./UITemplate";
import StartModal from "./StartModal";
import styles from "./Survey.module.scss";

export default compose(
  ({
    data: { survey },
    answers,
    setAnswers,
    currentRowId,
    setCurrentRowId,
    handleFinishSurvey,
    setOpenModal,
    openModal,
    startModal,
    setStartModal,
    history,
    loading
  }) => (
    <div
      className={classNames(
        styles.wrapper,
        survey.type !== "Competencies survey" && styles.fixed
      )}
    >
      {survey.type !== "Competencies survey" && (
        <SurveyHeader
          survey={survey}
          history={history}
          answers={answers}
          currentRowId={currentRowId}
        />
      )}
      <UITemplate
        survey={survey}
        answers={answers}
        setAnswers={setAnswers}
        currentRowId={currentRowId}
        setCurrentRowId={setCurrentRowId}
        handleFinishSurvey={handleFinishSurvey}
      />
      <SurveyFooter
        survey={survey}
        answers={answers}
        currentRowId={currentRowId}
        setCurrentRowId={setCurrentRowId}
        loading={loading}
      />
      {survey.hasIntro && (
        <Modal
          closeTimeoutMS={350}
          isOpen={startModal}
          onRequestClose={() => setStartModal(false)}
          shouldCloseOnOverlayClick
          className="fullModal"
        >
          <StartModal
            survey={survey}
            setOpenModal={setStartModal}
            history={history}
          />
        </Modal>
      )}
    </div>
  )
);

// <Modal
//   isOpen={openModal && true}
//   onRequestClose={() => setOpenModal(false)}
//   shouldCloseOnOverlayClick
//   className={styles.modal}
// >
//   <Button
//     bStyle="close"
//     type="button"
//     onClick={() => setOpenModal(false)}
//   >
//     <i className="fas fa-times" />
//   </Button>
//   <div className={styles.modalWrapper}>
//     <div className={styles.innerWrapper}>
//       <div
//         className={styles.modalContent}
//         dangerouslySetInnerHTML={createMarkup({ survey })}
//       />
//       <Button
//         bStyle={["primary", "large"]}
//         type="button"
//         onClick={() => setOpenModal(false)}
//       >
//         Continue
//       </Button>
//     </div>
//   </div>
// </Modal>
//
