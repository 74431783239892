import React, { useRef, useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { useTour } from "@reactour/tour";
import TourStep from "components/UI/TourStep";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Modal from "react-modal";
import Icon from "components/UI/Icon";
import { useDebounce } from "utils/debounce";
import { ReactComponent as People } from "images/People.svg";
import { ReactComponent as TourImg } from "images/Tour.svg";
import EvaluateColIcon from "images/position/EvaluateCol";
import OnboardCol from "images/position/OnboardCol";
import CompareCol from "images/position/CompareCol";
import Button from "components/UI/Button";
import RemoveCandidate from "./RemoveCandidate";
import ConfirmModal from "./ConfirmationModal";

import Preperation from "./Preperation";

import Item from "./Item";

import compose from "./compose";

import styles from "./Kanban.module.scss";

export default compose(
  ({
    columns,
    position,
    actionHandlers,
    onDragEnd,
    updateModal,
    removeCandidateFromPosition,
    removeCandidateFromPositionLonglist,
    removeCandidateFromOrganization,
    history,
    removeModal,
    setRemoveModal,
    setOnboardModal,
    setConfirmModal,
    confirmModal,
    VIEWER_QUERY: { viewer },
    isTour,
    toggleShowPositionTour,
    collapsed,
    handleSendInvite,
  }) => {
    const [maxWidth, setMaxWidth] = useState(null);
    const [kanbanLeft, setKanbanLeft] = useState(null);
    const { setIsOpen, setSteps, beforeClose } = useTour();

    const kanbanRef = useRef(null);

    const maxEvaluate =
      position.requirements.length +
      (position.competenciesHaveBeenConfed && 3) +
      3;

    const steps = [
      {
        selector: "body",
        content: position.createdFromTemplate ? (
          <TourStep
            title="Welcome to the Hiring Dashboard!"
            text="Here you’ll be able to adjust the template to your specific needs and invite, evaluate, and compare candidates. Explore the available features with a quick tour designed to help you get started. "
            image={<TourImg />}
          />
        ) : (
          <TourStep
            title="Welcome to the Hiring Dashboard!"
            text="Here you’ll be able to define open positions, as well as invite and evaluate candidates. Explore the features available in the Hiring Dashboard with a quick tour designed to help you get started. "
            image={<TourImg />}
          />
        ),
      },
      {
        selector: ".positionMapping",
        content: () => {
          kanbanRef.current.scrollTo({
            left: 0,
            behavior: "auto",
          });

          if (position.createdFromTemplate) {
            return (
              <TourStep
                title="Start position mapping"
                text="Use these tools to adjust the Hiring Template to your exact needs."
              />
            );
          }
          return (
            <TourStep
              title="Start position mapping"
              text="Select the skills and competencies candidates are expected to have."
            />
          );
        },
      },
      {
        selector: ".invite",
        content: (
          <TourStep
            title="Build a shortlist"
            text="Invite shortlisted candidates to complete their Candidate Profiles for later review."
          />
        ),
      },
      {
        selector: "#positionDropdownMenu",
        content: (
          <TourStep
            title="Actions menu"
            text="Here you will find defferent actions you can perform."
          />
        ),
        action: () => {
          document.querySelector("#positionDropdownMenuTrigger").click();
        },
      },
      {
        selector: ".inviteStakeholders",

        content: () => {
          return (
            <TourStep
              title="Stakeholder participation"
              text="Invite stakeholders to get to know the candidates and participate in the candidate hiring and evaluation process."
            />
          );
        },
      },
      {
        selector: ".interviewColumn",
        action: () => {
          document.querySelector("#positionDropdownMenuTrigger").click();
        },
        content: (props) => (
          <TourStep
            title="Interview and evaluate candidates"
            text="Use the included interview and evaluation functionality to make hiring decisions free of bias."
          />
        ),
      },
      {
        selector: ".compare",
        content: () => {
          kanbanRef.current.scrollTo({
            left: 400,
            behavior: "auto",
          });

          return (
            <TourStep
              title="Candidate comparison"
              text="Compare candidate evaluation results with a full breakdown of comments and scores given by all stakeholders."
            />
          );
        },
      },
      {
        selector: ".onboardColumn",
        content: () => {
          kanbanRef.current.scrollTo({
            left: kanbanRef.current.scrollWidth,
            behavior: "auto",
          });

          return (
            <TourStep
              title="Candidate onboarding"
              text="Go beyond company policies and super-charge new employee onboarding with actionable team and talent data."
            />
          );
        },
      },
    ];

    const sidebarRef = document.getElementById("sidebar");

    const calculateKanbanSizes = useCallback(() => {
      if (!sidebarRef) return;

      const resizeObserver = new ResizeObserver(() => {
        if (
          document.getElementById("sidebar").offsetWidth < 80 &&
          window.innerWidth > 960
        ) {
          // If sidebar is closed
          setKanbanLeft(-document.getElementById("wrapper").offsetLeft);
          setMaxWidth(
            window.innerWidth +
              50 -
              document.getElementById("wrapper").offsetLeft
          );

          if (window.innerWidth > 1450) {
            setMaxWidth(
              window.innerWidth +
                194 -
                document.getElementById("wrapper").offsetLeft
            );
          }
        } else if (window.innerWidth > 960) {
          setKanbanLeft("0");
          setMaxWidth(
            window.innerWidth -
              document.getElementById("sidebar").offsetWidth -
              24
          );
        }

        if (
          window.innerWidth - document.getElementById("sidebar").offsetWidth >
          1900
        ) {
          setKanbanLeft("-262");
          setMaxWidth(
            window.innerWidth - document.getElementById("sidebar").offsetWidth
          );
        }
      });

      resizeObserver.observe(sidebarRef);

      return () => resizeObserver.disconnect(); // clean up
    }, []);

    useEffect(() => {
      if (isTour) {
        setSteps(steps);
        beforeClose(toggleShowPositionTour());
        setIsOpen(true);
      }

      calculateKanbanSizes();
    }, [isTour]);

    window.addEventListener(
      "resize",
      useDebounce(() => {
        calculateKanbanSizes();
      }, 400),
      true
    );

    const prepsComplete =
      position.requirements.length > 0 &&
      position.competenciesHaveBeenConfed &&
      position.summary &&
      position.summary !== "" &&
      position.summary !== "<p></p>" &&
      position.summary !== "<h1></h1>" &&
      position.summary !== "<h2></h2>" &&
      position.summary !== " ";

    return (
      <div className={styles.main}>
        <div
          ref={kanbanRef}
          className={classNames(
            styles.kanbanWrapper,
            collapsed && styles.collapsed
          )}
          style={{
            left: `${kanbanLeft}px`,
            maxWidth: `${maxWidth - 100}px`,
            width: `${maxWidth - 100}px`,
          }}
          id="kanbanWrapper"
        >
          <div
            style={{ width: `1690px` }}
            className={styles.kanban}
            id="kanban"
          >
            <DragDropContext onDragEnd={onDragEnd}>
              {position.kanbanColumns.map((column, index) => (
                <div
                  id={`kanbanColumn-${index}`}
                  className={classNames(
                    "kanbanColumn",
                    styles.column,
                    column.title === "Interview & Evaluate" &&
                      "interviewColumn",
                    column.title === "Onboard" && "onboardColumn"
                  )}
                  key={`kanban-column-${column.id}`}
                >
                  <div className={styles.colHead}>
                    <div className={styles.top}>
                      <h5 className={styles.title}>{column.title}</h5>
                      {column.action && (
                        <div
                          className={
                            (index === 0 && "invite") ||
                            (index === 2 && "compare")
                          }
                        >
                          <Button
                            bStyle={["newsimple", "white", "borderradius"]}
                            onClick={() => actionHandlers[column.action]()}
                          >
                            {column.actionTitle}
                          </Button>
                        </div>
                      )}
                    </div>
                    <p className={styles.candidateAmount}>{`${
                      columns[column.index].length
                    } candidates`}</p>
                  </div>
                  <div className={styles.cards} id="columnWrapper">
                    <Droppable
                      droppableId={column.index.toString()}
                      className={styles.droppableBlock}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={styles.droppable}
                        >
                          {columns[column.index].map((item, index) => (
                            <Item
                              position={position}
                              column={column.title}
                              item={item}
                              key={item.itemId}
                              index={index}
                              columnIndex={column.index}
                              maxEvaluate={maxEvaluate}
                              setRemoveModal={setRemoveModal}
                              updateModal={updateModal}
                              setOnboardModal={setOnboardModal}
                              setConfirmModal={setConfirmModal}
                              handleSendInvite={handleSendInvite}
                              viewer={viewer}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    {index === 0 && columns[0].length < 1 && (
                      <div className={styles.placeholderWrapper}>
                        <div className={styles.placeholder}>
                          <div className={styles.image}>
                            <People />
                          </div>
                          {!prepsComplete && (
                            <p>
                              Please complete Position mapping first before
                              adding candidates.
                            </p>
                          )}

                          <Button
                            bStyle={["primary", "verySmall"]}
                            type="button"
                            onClick={() => updateModal("inviteCandidates")}
                          >
                            <Icon icon="AddUser" marginRight />
                            Add candidates
                          </Button>
                        </div>
                      </div>
                    )}
                    {index === 2 && columns[2].length < 1 && (
                      <div className={styles.placeholderWrapper}>
                        <div className={styles.placeholder}>
                          <div className={styles.image}>
                            <EvaluateColIcon />
                          </div>
                          <p>
                            After interview drag candidates here to mark them
                            ready for evaluation.
                          </p>
                          <Button
                            bStyle={["newsimple", "nopadding", "small"]}
                            type="button"
                            onClick={() => updateModal("evaluateInfo")}
                          >
                            Learn more
                          </Button>
                        </div>
                      </div>
                    )}
                    {index === 3 && columns[3].length < 1 && (
                      <div className={styles.placeholderWrapper}>
                        <div className={styles.placeholder}>
                          <div className={styles.image}>
                            <CompareCol />
                          </div>
                          <p>
                            Once ready, start comparing candidates on our
                            compare table.
                          </p>
                          <Button
                            bStyle={["newsimple", "nopadding", "small"]}
                            type="button"
                            onClick={() => updateModal("compareInfo")}
                          >
                            Learn more
                          </Button>
                        </div>
                      </div>
                    )}
                    {index === 4 && columns[4].length < 1 && (
                      <div className={styles.placeholderWrapper}>
                        <div className={styles.placeholder}>
                          <div className={styles.image}>
                            <OnboardCol />
                          </div>
                          <p>Start the onboarding process in Wisnio.</p>
                          <Button
                            bStyle={["newsimple", "nopadding", "small"]}
                            type="button"
                            onClick={() => updateModal("onboardInfo")}
                          >
                            Learn more
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </DragDropContext>
          </div>
        </div>
        <Modal
          isOpen={removeModal && true}
          onRequestClose={() => setRemoveModal(false)}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <RemoveCandidate
            removeModal={removeModal}
            setRemoveModal={setRemoveModal}
            setConfirmModal={setConfirmModal}
            removeCandidateFromPosition={removeCandidateFromPosition}
            removeCandidateFromPositionLonglist={
              removeCandidateFromPositionLonglist
            }
            removeCandidateFromOrganization={removeCandidateFromOrganization}
            position={position}
            viewer={viewer}
          />
        </Modal>
        <Modal
          isOpen={confirmModal && true}
          onRequestClose={() => setConfirmModal(false)}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <ConfirmModal
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            setRemoveModal={setRemoveModal}
            removeCandidateFromOrganization={removeCandidateFromOrganization}
          />
        </Modal>
      </div>
    );
  }
);
