import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import AddEvaluator from "./AddEvaluator";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import UserAvatar from "components/UserAvatar";
import AssessmentScorecardModal from "components/PositionTemplates/AssessmentScorecardModal";
import EvaluationSteps from "./EvaluationSteps";
import OverviewSection from "./OverviewSection";
import { useSidebar } from "utils/sidebarProvider";
import FullPage from "components/Loaders/FullPage";
import { printoutRoute } from "client/authRoutes";

import compose from "./compose";

import styles from "./Interviewguide.module.scss";

export const iconsAndColors = {
  Experience: {
    icon: "Businessman",
    color: "primary",
  },
  "Leadership Span": {
    icon: "Extraversion",
    color: "yellow",
  },
  "Growth Potential": {
    icon: "Plant",
    color: "green",
  },
  "Potential Realization": {
    icon: "Muscle",
    color: "purple",
  },
};

export default compose(
  ({
    CANDIDATE_QUERY: { candidateInTeam },
    ASSESSMENT_SCORECARD: { assessmentScorecard },
    viewer,
    currentTab,
    setCurrentTab,
    overlayClose,
    setOverlayClose,
    openModal,
    updateModal,
    list,
    match: {
      params: { candidateType },
    },
  }) => {
    if (!candidateInTeam || !assessmentScorecard) {
      return <FullPage />;
    }

    const [openScorecardModal, setOpenScorecardModal] = useState(false);
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const listItems = [];

    list.map((item, index) => {
      listItems.push({
        title: item,
        action: () => setCurrentTab(index),
        style: currentTab === index ? "active" : "",
      });
    });

    const sidebarContent = [
      {
        items: listItems,
      },
    ];

    useEffect(() => {
      if (currentTab === 0 || currentTab === 2) {
        setContent(sidebarContent);
      }
    }, [currentTab]);

    return (
      <>
        <div className={styles.main}>
          <SidebarWrapper noFooter wide>
            <SidebarHeader title={"Candidate evaluation"}>
              <Button
                bStyle={["verySmall"]}
                type="button"
                onClick={() => updateModal("addEvaluators")}
              >
                Add interviewers
              </Button>
              <Modal
                closeTimeoutMS={350}
                isOpen={openModal === "addEvaluators"}
                onRequestClose={() => updateModal("")}
                shouldCloseOnOverlayClick={overlayClose}
                shouldCloseOnEsc={overlayClose}
                className={styles.modal}
              >
                <AddEvaluator
                  updateModal={updateModal}
                  setOverlayClose={setOverlayClose}
                />
              </Modal>
              {currentTab === 2 && (
                <a
                  href={`${printoutRoute}/printout/evaluation_report/${candidateInTeam.position.parent_org_id}/${candidateInTeam.position.parent_team_id}/${candidateInTeam.position.id}/${candidateType}/${candidateInTeam.id}/${assessmentScorecard.id}/${candidateInTeam.first_name}_${candidateInTeam.last_name}_evaluation_report.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="button" bStyle={["secondary", "verySmall"]}>
                    <Icon icon="Print" marginRight />
                    Print PDF
                  </Button>
                </a>
              )}
              {(currentTab === 0 || currentTab === 1) && (
                <>
                  <Link
                    to={`/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}`}
                  >
                    <Button bStyle={["none"]}>
                      <span className={styles.close}>
                        <Icon icon="Close" />
                      </span>
                    </Button>
                  </Link>
                </>
              )}
              {currentTab === 2 && (
                <>
                  <Link
                    to={`/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}`}
                  >
                    <Button
                      bStyle={["primary", "verySmall"]}
                      type="button"
                      onClick={() => setCurrentTab(2)}
                    >
                      <span className={styles.arrow}>
                        <Icon icon="Arrow" />
                      </span>
                      Back to position
                    </Button>
                  </Link>
                  <Link
                    to={`/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}`}
                  >
                    <Button bStyle={["none"]}>
                      <span className={styles.close}>
                        <Icon icon="Close" />
                      </span>
                    </Button>
                  </Link>
                </>
              )}
            </SidebarHeader>
            <div className={styles.content}>
              {currentTab === 0 && (
                <div className={styles.mainRow}>
                  <div className={styles.mainLeft}>
                    <div className={styles.head}>
                      <div className={styles.left}>
                        <h2 className={styles.title}>
                          <span className={styles.icon}>
                            <Icon icon="Clipboard" />
                          </span>
                          Interview & Evaluate
                        </h2>
                        <div className={styles.row}>
                          <div className={styles.candidate}>
                            <UserAvatar
                              member={candidateInTeam}
                              size="medium"
                            />
                            <div className={styles.right}>
                              <h4 className={styles.name}>
                                {candidateInTeam.name}
                              </h4>
                              <p className={styles.email}>
                                {candidateInTeam.email}
                              </p>
                            </div>
                          </div>
                          <Button
                            bStyle={["primary", "small"]}
                            onClick={() => setCurrentTab(1)}
                          >
                            Start evaluation
                            <span className={styles.icon}>
                              <Icon icon="Arrow" />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <p className={styles.basedOn}>
                      This assessment is based on:
                    </p>
                    <div className={styles.template}>
                      <Button
                        bStyle={["none"]}
                        onClick={() => setOpenScorecardModal(true)}
                      >
                        <div className={styles.templateTitle}>
                          <div className={styles.templateIcon}>
                            <Icon icon="File" />
                          </div>
                          <div
                            className={classNames(
                              styles.templateIcon,
                              styles.hover
                            )}
                          >
                            <Icon icon="Search" />
                          </div>
                          <h5>{assessmentScorecard.position}</h5>
                        </div>
                      </Button>
                      <div className={styles.labels}>
                        <span className={styles.label}>
                          {assessmentScorecard.category}
                        </span>
                        <span
                          className={classNames(styles.label, styles.stage)}
                        >
                          {assessmentScorecard.stage}
                        </span>
                      </div>
                    </div>
                    <div className={styles.info}>
                      <p className={styles.infoTitle}>Impact</p>
                      <h5 className={styles.infoName}>
                        L4 - Defines enterprise strategy
                      </h5>
                      <p className={styles.infoText}>
                        Constructs entire systems and strategies for the
                        organization.
                      </p>
                      <p className={styles.infoTitle}>Indicative roles</p>
                      <p className={styles.infoText}>
                        CEO - Listed Organization; Management Board or
                        Divisional Leader in large Global Enterprise
                      </p>
                    </div>
                  </div>
                  <div className={styles.mainRight}></div>
                </div>
              )}
              {currentTab === 1 && (
                <EvaluationSteps
                  steps={assessmentScorecard.assessment_rows}
                  assessmentRows={assessmentScorecard.assessment_rows}
                  candidate={candidateInTeam}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  viewer={viewer}
                  setContent={setContent}
                />
              )}
              {currentTab === 2 && (
                <>
                  <div className={styles.head}>
                    <div className={styles.row}>
                      <h2 className={styles.title}>
                        <span className={styles.icon}>
                          <Icon icon="Clipboard" />
                        </span>
                        Final report
                      </h2>
                      <div
                        className={classNames(
                          styles.candidate,
                          styles.marginLeft
                        )}
                      >
                        <UserAvatar member={candidateInTeam} size="medium" />
                        <div className={styles.right}>
                          <h4 className={styles.name}>
                            {candidateInTeam.name}
                          </h4>
                          <p className={styles.email}>
                            {candidateInTeam.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <OverviewSection
                    candidate={candidateInTeam}
                    viewer={viewer}
                    assessmentScorecard={assessmentScorecard}
                    assessmentRows={assessmentScorecard.assessment_rows}
                  />
                </>
              )}
            </div>
          </SidebarWrapper>
          {!!openScorecardModal && (
            <Modal
              closeTimeoutMS={350}
              isOpen={!!openScorecardModal}
              onRequestClose={() => setOpenScorecardModal(false)}
              shouldCloseOnOverlayClick
              className={classNames(styles.modal, "modal")}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => {
                  setOpenScorecardModal(false);
                }}
              >
                <i className="fal fa-times" />
              </Button>
              <AssessmentScorecardModal
                assessmentScorecard={assessmentScorecard}
              />
            </Modal>
          )}
        </div>
      </>
    );
  }
);
